export const DPP_POLICY = {
    title:"Data Protection Policy",
    lastUpdatedDate:"Last Updated: 1 December 2024",
    introductions:[],
    terms:[
        {
            title:"Introduction",
            introductions:[],
            firstSubList:[
                {
                    paragraph:"Sequence SG BizCo Pte. Ltd. (“Sequence”, “we”, “us”, or “our”) operates the website www.sequence.sg (“Website”), and provides services. We take our responsibilities under Singapore’s Personal Data Protection Act 2012 (the “PDPA”) seriously. We also recognise the importance of the personal data that you entrust to us and believe that it is our responsibility to properly manage, protect and process personal data provided to us.",
                    secondSubList:[]
                },
                {
                    paragraph:"This Data Protection Policy is designed to assist you in understanding how we collect, use, disclose and/or process the personal data you have provided to us, as well as to assist you in making an informed decision before providing us with any personal data.",
                    secondSubList:[]
                },
                {
                    paragraph:"This Data Protection Policy supplements our Terms of Service and together they set out the basis on which we collect, use, disclose and process any personal data we collect from you, the individuals you represent, our clients, customers or visitors to our Website. Unless otherwise defined herein, all defined terms shall have the meanings given to them in our Terms of Service. ",
                    secondSubList:[]
                },
                {
                    paragraph:"By accessing our Website and/or using our services, you hereby agree to be bound by the terms of this Data Protection Policy. If you do not agree with any term of this Data Protection Policy, you should not access our Website or our services. ",
                    secondSubList:[]
                },
                {
                    paragraph:"If you have any queries on this policy or how we may manage, protect, or process personal data, our Data Protection Officer (“DPO”) may be contactable at dpo@sequence.sg.",
                    secondSubList:[]
                },
                {
                    paragraph:"We reserve the right to amend the terms of this Data Protection Policy at our sole discretion. Any amended Data Protection Policy will be posted on our Website, and we will use commercially reasonable methods to notify you of such amendments. you shall be deemed to have accepted our published Data Protection Policy as amended by continuing to access our Website or use our services. ",
                    secondSubList:[]
                },
                {
                    paragraph:"Our Website may, from time to time, contain links to and from the websites of our partner networks, advertisers, affiliates or other third parties. If you follow a link to any of these websites, you should be aware that these websites have their own data protection or privacy policies. As these websites are not owned or operated by us, we do not accept any responsibility or liability for the contents of these websites and their data protection or privacy policies, and you access and provide personal data to these third-party websites at their own risk. Please check these policies before submitting any personal data to any such websites.",
                    secondSubList:[]
                }
            ],
        },
        {
            title:"Information We Collect and How We Collect It",
            introductions:[],
            firstSubList:[
                {
                    paragraph:"We may collect and process personal data such as:",
                    secondSubList:[
                        {
                            paragraph:"names, identification details (e.g. NRIC number, FIN number, passport number, biometric data), contact details, registered address, email address, and other information provided by you;",
                            thirdSubList:[]
                        },
                        {
                            paragraph:"financial account information such as billing address, banking details and other payment information;",
                            thirdSubList:[]
                        },
                        {
                            paragraph:"copies of documents containing the data described in 2.1(a) and 2.1(b);",
                            thirdSubList:[]
                        },
                        {
                            paragraph:"if you contact us for any reason, we may keep a record of that correspondence;",
                            thirdSubList:[]
                        },
                        {
                            paragraph:"personal data that may be captured via any error logging and reporting tool that captures error report data and, at your option and with your consent, send this data to us in order for us to be informed of any software errors or problems that may occur during the use of the Website and services; and",
                            thirdSubList:[]
                        },
                        {
                            paragraph:"details of visits to the Website and services, the activities you engage in when using the Website and services, the resources that you access on or via the Website and services, and the data provided to us in connection with or ancillary to these.",
                            thirdSubList:[]
                        },
                    ]
                },
                {
                    paragraph:"We collect personal data at when you use our Website and services, including but not limited to:",
                    secondSubList:[
                        {
                            paragraph:"when you visit or access our Website or services;",
                            thirdSubList:[]
                        },
                        {
                            paragraph:"when we correspond with you for whatever reason including in conducting KYC, due diligence, feedback, surveys or general correspondence; or",
                            thirdSubList:[]
                        },
                        {
                            paragraph:"when you visit our Website for whatever reason.",
                            thirdSubList:[]
                        }
                    ]
                },
                {
                    paragraph:"Notwithstanding the above, there will be information that we collect which are mandatory in order to provide our services to you as part of being regulated by the Accounting and Corporate Regulatory Authority (“ACRA”), the Monetary Authority of Singapore (“MAS”), and any other regulatory authoritative body of Singapore, without which we would not be able to provide you our services. In order to comply with such laws and regulations, we may use independent service providers to help us carry out due diligence and know your client checks. When this happens, you may be required to provide personal data (such as your NRIC / Identification Card / Passport or a photo), the exact information of which may vary depending on the checks being carried out. ",
                    secondSubList:[]
                },
                {
                    paragraph:"If you do not provide us with the personal data as may be required by us, or choose not to consent to our processing of your personal data, we may not be able to provide some or all of our services, or respond to other requests. We reserve the right to decline to provide any or all of our services to you should you decide not to consent to our collection, use, disclosure and/or processing of personal data that is required by us.",
                    secondSubList:[]
                }
            ]
        },
        {
            title:"Cookies",
            introductions:[],
            firstSubList:[
                {
                    paragraph:"We may use cookies on our Website. A cookie is a text file that is transferred to a visitor’s device (e.g. laptop. Mobile, tablet) so that the Website or website can remember who the visitor is. Cookies only record those areas of a Website or website that have been visited by a visitor’s device and for how long.",
                    secondSubList:[]
                },
                {
                    paragraph:"you have the ability to accept or decline such cookies. If you would like to do this, please see the help menu of your browser. However, you may not be able to use all the interactive features of the Website and/or services if some cookies are disabled.",
                    secondSubList:[]
                },
            ]
        },
        {
            title:"How We Use Personal Data",
            introductions:[],
            firstSubList:[
                {
                    paragraph:"We may collect, use and process your personal data for one or more of the following purposes:",
                    secondSubList:[
                        {
                            paragraph:"to facilitate your use of our Website, to operate our Website and to provide our services;",
                            thirdSubList:[]
                        },
                        {
                            paragraph:"to enter into an agreement for the provision of services to you;",
                            thirdSubList:[]
                        },
                        {
                            paragraph:"to verify your identity;",
                            thirdSubList:[]
                        },
                        {
                            paragraph:"to conduct marketing activities including market research, customer profiling, customer insights and targeted marketing activities;",
                            thirdSubList:[]
                        },
                        {
                            paragraph:"to send you notifications and marketing messages in relation to our promotional events, offers, opportunities, products, benefits and programmes, if so consented by you;",
                            thirdSubList:[]
                        },
                        {
                            paragraph:"to respond to, handle, and process queries, requests, applications, complaints, and feedback from you;",
                            thirdSubList:[]
                        },
                        {
                            paragraph:"to contact you through the contact information provided by you in order to provide you with information that you request from us;",
                            thirdSubList:[]
                        },
                        {
                            paragraph:"to correspond with us and to collect information relating to online interactions with us (including, for example, your IP address and the pages you view) so that we can offer you a more consistent and personalised experience in your relationship with us;",
                            thirdSubList:[]
                        },
                        {
                            paragraph:"to store, host and/or back up (whether for disaster recovery or otherwise) personal data, whether within or outside Singapore;",
                            thirdSubList:[]
                        },
                        {
                            paragraph:"for record-keeping purposes;",
                            thirdSubList:[]
                        },
                        {
                            paragraph:"to conduct research, analysis and development activities (including but not limited to data analytics, surveys and/or profiling) to improve the Website and services;",
                            thirdSubList:[]
                        },
                        {
                            paragraph:"to notify you of any administrative updates that are not marketing or advertising in nature;",
                            thirdSubList:[]
                        },
                        {
                            paragraph:"to respond to any legal processes, pursue legal rights and remedies, and manage any complaints or claims;",
                            thirdSubList:[]
                        },
                        {
                            paragraph:"to respond to requests for information from public and governmental / regulatory authorities, statutory boards, related companies, whether in Singapore or abroad, for audit, compliance, investigation and inspection purposes;",
                            thirdSubList:[]
                        },
                        {
                            paragraph:"to inform you of updates on and/or changes to our programmes, policies, terms and conditions, data protection policy, updates and other administrative information;",
                            thirdSubList:[]
                        },
                        {
                            paragraph:"to comply with any applicable law, regulation, legal process or government request;",
                            thirdSubList:[]
                        },
                        {
                            paragraph:"for any other purposes for which you have provided the information; and",
                            thirdSubList:[]
                        },
                        {
                            paragraph:"for any other incidental purposes related to or in connection with the purposes as set out above or otherwise described in this Data Protection Policy.",
                            thirdSubList:[]
                        }
                    ]
                }
            ]
        },
        {
            title:"Personal Data Disclosed to Us by you",
            introductions:[],
            firstSubList:[
                {
                    paragraph:"You represent, undertake and warrant that:",
                    secondSubList:[
                        {
                            paragraph:"for any personal data of individuals that you will be or may be disclosing or disclose to us, that you would have prior to disclosing such personal data to us obtained consent from the individuals whose personal data are being disclosed, to:",
                            thirdSubList:[]
                        },
                        {
                            paragraph:"permit you to disclose the individuals’ personal data to us for the purposes set out in this Data Protection Policy; and",
                            thirdSubList:[]
                        },
                        {
                            paragraph:"permit us (whether in Singapore or overseas) to collect, use, disclose and/or process the individuals’ personal data for the purposes set out in this Data Protection Policy;",
                            thirdSubList:[]
                        },
                        {
                            paragraph:"you shall notify the individuals whose personal data are disclosed to us of the relevant purposes for such disclosure; ",
                            thirdSubList:[]
                        },
                        {
                            paragraph:"any personal data of individuals that you disclose or will be disclosing to us is accurate. Further, you shall give us notice in writing as soon as reasonably practicable should you be aware that any such personal data has been updated and/or changed after such disclosure; ",
                            thirdSubList:[]
                        },
                        {
                            paragraph:"you shall at our request, assist us to comply with all applicable data protection legislation or laws, including but not limited to the PDPA; and",
                            thirdSubList:[]
                        },
                        {
                            paragraph:"for any personal data of individuals that are being provided to us, that you are validly acting on behalf of such individuals and that you have the authority of such individuals to provide their personal data to us and for us to collect, use, disclose and process such personal data for the purposes set out in this Data Protection Policy.",
                            thirdSubList:[]
                        }
                    ]
                },
                {
                    paragraph:"Without prejudice to the foregoing, you shall ensure that you comply with applicable data protection laws, and that you will not do anything and not omit to do anything that will cause us to be in breach of any provision or requirement of such applicable data protection laws, whether now or in the future. you shall, at our request, promptly do such things or execute such documents, as determined by us, in order to facilitate our compliance with the applicable data protection laws.",
                    secondSubList:[]
                },
                {
                    paragraph:"Notwithstanding anything to the contrary, you undertake to indemnify and at all times hereafter to keep us (together with our respective officers, employees and agents) (each an “Injured Party”) indemnified against any and all direct and indirect losses, damages, actions, proceedings, costs, claims, demands, liabilities (including full legal costs on a solicitor and own client basis) which may be suffered or incurred by the Injured Party or asserted against the Injured Party by any person or entity whatsoever, in respect of any matter or event whatsoever arising out of, in the course of, by reason of or in respect of:",
                    secondSubList:[
                        {
                            paragraph:"any breach of any of the provisions in this clause 5; and/or",
                            thirdSubList:[]
                        },
                        {
                            paragraph:"any action or omission that causes us to be in breach of the PDPA or any other applicable law.",
                            thirdSubList:[]
                        }
                    ]
                },
            ]
        },
        {
            title:"Disclosure of Personal Data",
            introductions:[],
            firstSubList:[
                {
                    paragraph:"Personal data provided to us by you may be used, disclosed, maintained, accessed, processed and/or transferred to the following third parties, whether sited in Singapore or outside of Singapore, for the purposes set out below:",
                    secondSubList:[
                        {
                            paragraph:"our affiliates, subsidiaries, and group companies;",
                            thirdSubList:[]
                        },
                        {
                            paragraph:"third party service providers which require the processing of personal data, for example, third party service providers which have been engaged by us:",
                            thirdSubList:[
                                {
                                    paragraph:"to provide and maintain any IT equipment used to store and access personal data;"
                                },
                                {
                                    paragraph:"to host and maintain the Website and services;"
                                },
                                {
                                    paragraph:"or otherwise in connection with the provision of the services;"
                                }
                            ]
                        },
                        {
                            paragraph:"our auditors and legal advisors;",
                            thirdSubList:[]
                        },
                        {
                            paragraph:"public and governmental / regulatory authorities, statutory boards, industry associations, whether in Singapore or abroad; and/or",
                            thirdSubList:[]
                        },
                        {
                            paragraph:"courts and other alternative dispute forums.",
                            thirdSubList:[]
                        }
                    ]
                },
                {
                    paragraph:"In certain circumstances we may provide third parties (whether or not located in Singapore) with aggregate information about our you. This may include information about your devices, including where available your IP address, operating system and browser type, for system administration and to report aggregate information to our advertisers. This is anonymised statistical data about your browsing actions and patterns, and does not identify any individual.",
                    secondSubList:[]
                },
                {
                    paragraph:"We may also disclose personal data in order to comply with any legal obligation, or in order to enforce or apply any terms and conditions between you and us, or to protect the rights, property, or safety of any person (including for example for the purposes of fraud detection and prevention).",
                    secondSubList:[]
                },
            ]
        },
        {
            title:"Transfer of Personal Data Outside of Singapore",
            introductions:[],
            firstSubList:[
                {
                    paragraph:"The personal data that we collect from you may be transferred to, used, processed and stored outside of Singapore for one or more of the purposes set out above. By submitting personal data and/or using the Website and  services, you agree and consent to such transfer, storing and/or processing.",
                    secondSubList:[]
                },
                {
                    paragraph:"We will take reasonable steps to maintain appropriate physical, technical and administrative security to help prevent the loss, misuse, unauthorised access, disclosure or modification of personal data in our possession.",
                    secondSubList:[]
                },
            ]
        },
        {
            title:"Updating your Information",
            introductions:[],
            firstSubList:[
                {
                    paragraph:"UWhen you submit personal data to us, you should ensure such personal data is accurate, and let us know if such personal data changes so that we are not holding any inaccurate personal data about you.",
                    secondSubList:[]
                },
                {
                    paragraph:"You may request to update or amend your personal data by notifying us through email.",
                    secondSubList:[]
                },
            ]
        },
        {
            title:"Retention of Personal Data",
            introductions:[],
            firstSubList:[
                {
                    paragraph:"Your personal data will be retained by us for as long as the original purposes or the legal or business purposes for which your personal data was collected continues. If retention is no longer necessary, we will use reasonable efforts to delete, destroy or de-identify your personal data unless retention of the same is required to satisfy legal or regulatory requirements or to protect our interests or in accordance with our policies.",
                    secondSubList:[]
                }
            ]
        },
        {
            title:"Your Rights",
            introductions:[],
            firstSubList:[
                {
                    paragraph:"Subject to Clause 2.3 of this Data Protection Policy and to any applicable laws, you may withdraw your consent for us to collect, use, disclose and/or process your personal data for some or all of the purposes listed in this Data Protection Policy.",
                    secondSubList:[]
                },
                {
                    paragraph:"You may request to access or correct the personal data currently in our possession by emailing our DPO using the contact details. Please note that we may charge you a reasonable fee for the handling and processing of your requests to access your personal data.",
                    secondSubList:[]
                }
            ]
        }
    ]
}