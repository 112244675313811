export const TERMS_OF_SERVICE = {
    title:"Terms of Service",
    lastUpdatedDate:"Last Updated: 1-Dec-2024",
    introductions:[
        "The Services provided by Sequence SG BizCo Pte. Ltd. (and any of its affiliates) (“Sequence”, “we”, “us”, or “our”) to you (and/or the entity, company or organisation that you represent shall be governed by these Terms of Service. These Terms of Service include and incorporate by reference our Data Protection Policy (“Data Protection Policy”). Any of our Services provided to you pursuant to a Letter of Engagement are subject to these Terms of Service and shall form a binding agreement between us and you (“Agreement”). Your instruction for us to commence provision of the Services shall constitute your acceptance of the Agreement.",
        "If you are a representative or agent of an entity, company or organisation, you are agreeing to the Agreement on behalf of that entity, company or organisation, and represent and warrant that you are duly authorised to bind such entity, company or organisation, and in the absence of such authority, you agree to indemnify us for all losses, Damages, costs (including solicitors’ fees), claims, or actions brought against us, by such entity, company or organisation or otherwise, and you shall also procure the ratification of the same, and shall be liable for any losses, Damages, or costs (including solicitors’ fees), claims, or actions incurred or brought against us as a result of any failure to ratify.",
        "These Terms of Service may be revised from time to time by us, which we may notify you of via the registered email address or agreed mode of communication. Continued use of our Services after such revision to these Terms of Service had come into effect, shall constitute acceptance of the revised Agreement. If you do not accept any part of such revisions, you must immediately inform us to cease provision of our Services."
    ],
    terms:[
        {
            title:"Services",
            introductions:[],
            firstSubList:[
                {
                    paragraph:"Our Services are subject at all times to the Agreement and any other terms that may be agreed between Sequence and you. In the event of any conflict, unless such clause is specifically referenced to in the Letter of Engagement, these Terms of Service shall prevail.",
                    secondSubList:[]
                },
                {
                    paragraph:"In using our Services, you undertake to provide us with instructions, information, materials and documentation that are reliable, complete, accurate, detailed and up to date. We shall be entitled to rely upon the reliability, completeness, accuracy, details, and currency of all instructions and information provided to us by you, or by others on behalf of you, without independently verifying it, in the provision of our Services. We shall not be liable to you in any manner whatsoever arising out of or in connection with any instructions or information that does not comply with the foregoing. In addition, you agree to indemnify Sequence and any of our affiliates (including any Sequence Personnel) against any and all losses, Damages, costs (including solicitors’ fees), claims,  actions, penalties, or fines incurred by Sequence or any of our affiliates (including any Sequence Personnel) arising out of or in connection with performing our Services in accordance with or in reliance of any part of your instructions or information provided. For the avoidance of doubt, the affiliates of Sequence and Sequence Personnel shall be entitled to enforce any term of the Agreement pursuant to and in accordance with the Contracts (Rights of Third Parties) Act 2001 of Singapore.",
                    secondSubList:[]
                },
                {
                    paragraph:"You shall provide us with all relevant or material documents, information, representations, endorsements, instructions, or approvals related to your entity and related entities or individuals, in connection with the provision of our Services, in a clear and timely manner. We shall not be liable for any resulting consequences, and we shall not be taken to be in breach if such consequence arises, whether in part or in whole, from any inaccuracy, incompleteness, misleadingness, or defectiveness of such documents, information, representations, endorsements, instructions or approvals. In other words, you are aware of the dependencies on your part in relation to the provision of our Services, which include:",
                    secondSubList:[
                        {
                            paragraph:"the timely approvals or endorsements, as required, by the managers, directors, officers or members of the entity that you represent;",
                            thirdSubList:[]
                        },
                        {
                            paragraph:"the timely provision of any change in circumstances or information in you or in relation to the entity that you represent that we would not be aware of unless updated by you;",
                            thirdSubList:[]
                        },
                        {
                            paragraph:"the timely provision of your financial statements and other financial information, or that of the entity that you represent.",
                            thirdSubList:[]
                        }
                    ]
                },
                {
                    paragraph:"You agree that there may be certain rectifications or actions required on the part of us (along with the full co-operation on your part) in order for us to perform our Services and our obligations under the Agreement. Such rectifications or actions may not be apparent to us at the time of quoting or prior to the provision of our Services to you. Examples include defective resolutions in the past, inaccurate statutory records, or accounting / financial errors, omissions, and backlogs. We reserve the right to send a revised quote that you may accept or reject. If you reject the revised quote, we shall refund the sums paid to us after deducting any reasonable amounts for any work done or assessments made, and for any disbursements etc. Where there is any conflicting information or failure to provide information or to resolve any queries on your part, Sequence shall be entitled to take the publicly available information as the baseline for the handover to Sequence to perform the Services, notwithstanding that it is accepted by both Parties that such publicly available information would not be the complete information relating to you, the entity(ies) in scope of the Services, or in relation to the Services. It is agreed by you that such rectification works may be urgently required, and that Sequence may, in certain circumstances, proceed with such works without express acceptance or approval and charge back to you for the fees and reimbursements as required. You also agree that Sequence is entitled to use your Data for the purposes of improving its internal processes.",
                    secondSubList:[]
                },
                {
                    paragraph:"In the event that any of our Services include receipt of documents or physical items (such as bank cards, or other deliveries):",
                    secondSubList:[
                        {
                            paragraph:"for physical mail, even if it states private & confidential or wording to that effect, we shall be entitled to open such mail to make digital copies to forward to you. The physical originals will be kept for a period of three (3) months in which you may collect them, after which we will shred these documents.",
                            thirdSubList:[]
                        },
                        {
                            paragraph:"for physical items, they shall be kept at our offices for a period of three (3) months in which you may collect them, after which we will courier them to you and invoice you such courier and any applicable administrative charges. ",
                            thirdSubList:[]
                        }
                    ]
                },
                {
                    paragraph:"While we will strive to send out reminders and work with reasonable efforts to ensure that such dependencies do not prevent or delay us from providing our Services to you, it is a condition precedent on your part to manage and clear such dependencies before we are obliged to provide our Services, and where such dependencies prevent or delay us from providing our Services, we shall not be taken to be in breach. All consequences of failure to manage or clear such dependencies shall be borne by you (e.g. penalties for late filing or additional applications to any government agencies or courts).",
                    secondSubList:[]
                },
                {
                    paragraph:"In order to provide our Services, we may require additional information from you including but not limited to supplemental agreements, know-your-client (“KYC”) due diligence forms, and other documents and information to comply with our policies and any applicable Law, regulation, guideline, or circular issued by any regulatory authority or other agencies or organisations, and for any other reason that Sequence may deem necessary from time to time. If you do not provide such information that we may require, we are not obliged to perform any of our Services and shall not be deemed to be in breach of any obligations owing to you.",
                    secondSubList:[]
                },
                {
                    paragraph:"We reserve the right to refuse to provide, and suspend, disable or terminate any of our Services, should the result of our due diligence be unsatisfactory at our sole discretion.",
                    secondSubList:[]
                },
                {
                    paragraph:"You agree that we are authorised to take any steps we deem necessary in our reasonable discretion to comply with any of your specific requirements or other legal obligations, including but not limited to the disclosure of your personal data to third parties in order to comply with regulatory obligations under relevant law. This includes soliciting for legal advice, engaging legal counsel and such other third parties on your behalf, and which you shall bear all costs and expenses arising thereof. These steps shall include any form of KYC processes and actions taken by us.",
                    secondSubList:[]
                },
                {
                    paragraph:"We shall also be entitled to collect, access, modify, distribute, audit or reproduce any of your Data to:",
                    secondSubList:[
                        {
                            paragraph:"provide, protect, and improve our products and services, whether now in existence or in the future;",
                            thirdSubList:[]
                        },
                        {
                            paragraph:"provide statistical analysis;",
                            thirdSubList:[]
                        },
                        {
                            paragraph:"protect the integrity of any data held by us;",
                            thirdSubList:[]
                        },
                        {
                            paragraph:"ensure your compliance with the Agreement and any applicable Laws; and",
                            thirdSubList:[]
                        },
                        {
                            paragraph:"disclose such data and information if required by Law or law enforcement authorities, to enforce the Agreement, or to protect our rights or those of other users.",
                            thirdSubList:[]
                        }
                    ]
                },
                {
                    paragraph:"We are entitled to immediately destroy, dispose of or delete any of your Data upon cessation of any Agreement or of our Services to you. Notwithstanding, we shall be entitled to retain any such Data provided to us in order to comply with any retention of records Laws.",
                    secondSubList:[]
                },
                {
                    paragraph:"Notwithstanding any services provided by Sequence or any Sequence Personnel, you acknowledge and agree that Sequence is not a law firm or practice and neither of the Sequence Personnel is acting as a lawyer (whether under the definition of the Legal Profession Act 1966 or otherwise). You further acknowledge and agree that Sequence and/or any Sequence Personnel is not engaged by you to provide legal services and shall not be taken to be providing any form of legal advice or services in their respective capacities. If you require legal advice and/or services, you acknowledge and agree that such advice and/or services are outside of the scope of our services, and you shall seek legal advice from the appropriate providers, such as a recognised legal practice.",
                    secondSubList:[]
                }
            ]
        },
        {
            title:"Fees and Payment Terms",
            introductions:[],
            firstSubList:[
                {
                    paragraph:"You shall pay the fees for our Services in accordance with the Agreement. Our Services may be on a one-off, ad hoc, or on a subscription or recurring basis, depending on the type of Service. It is agreed and understood that we are not obliged to commence, provide, or continue to provide our Services if any of our invoices or requests for payment are outstanding or unpaid, and such discontinuation of the provision of our Services shall not be taken as a breach on our part. In addition, we reserve the right to suspend, disable or terminate the provision of our Services in whole or in part.",
                    secondSubList:[]
                },
                {
                    paragraph:"Unless otherwise mutually agreed in writing or stated thereon, all our fees in respect of our Services are payable 100% in advance, and we shall not begin providing any services until the fees are collected in full. All Services (or along with any subscriptions), unless otherwise expressly discontinued by you, shall automatically renew thirty (30) days prior to the end of the applicable subscription period. You authorise Sequence to charge any digital payment method for the fees for the renewed subscription. There shall be no refund after such renewal payment has been made. Sequence may either invoice you via sending of a digital or physical invoice to the modes of contact communicated to us. Where there is a digital payment method prescribed by you, we shall be entitled to charge the payment method for the fees as per such invoices that may be sent to you. Where there is no digital payment method applicable, you shall make payment to us as per the payment terms via one of the means of payment below. Payment shall be due within thirty (30) calendar days of us sending the invoice. After fifteen (15) calendar days of such presentation, the amounts charged on the invoice will be conclusive if there are no disputes raised, or clarifications requested on the invoice. We reserve the right to charge interest on any overdue amounts at the rate of 1% per month, or at the maximum rate allowed by the applicable Laws.",
                    secondSubList:[]
                },
                {
                    paragraph:"Notwithstanding Clause 2.1 and 2.2, payment of our fees does not automatically constitute our acceptance of such and the provision of Services. ",
                    secondSubList:[]
                },
                {
                    paragraph:"Sequence also reserves the right to decline any subscription or purchase of any Service for any reason. In the event where we do not accept payment, or decline to provide any Service for whatever reason, we will inform you and refund any amounts paid. ",
                    secondSubList:[]
                },
                {
                    paragraph:"Our payment details are set out in our invoices. We shall be entitled to invoice you for our Services or additional services or fees (including disbursements) that are not contained in the subscriptions or displayed in your Account. ",
                    secondSubList:[]
                },
                {
                    paragraph:"All payment of our fees must be paid without counterclaim and free and clear of any withholding or deduction or set-off, and all charges incurred, including bank charges, and connected with such remittance shall be wholly borne by you. Sequence shall be entitled to set off any amounts owing to us from you against any of our obligations towards you.",
                    secondSubList:[]
                },
                {
                    paragraph:"You acknowledge and agree that if, for whatever reason, any payment is reversed or declined, where recurring payments are required, then your liability to us will automatically be deemed a debt immediately due and payable. Any outstanding payments that you owe us shall attract late payment interest of 1% per month or part thereof on a compounded basis from the due date until actual payment of the overdue amount, whether before or after judgment.",
                    secondSubList:[]
                },
                {
                    paragraph:"Our fees are subject to, and are exclusive of, any applicable tax, disbursements, costs, and expenses, such as filing fees paid to ACRA, stamp fees, and other out-of-pocket expenses, which shall be borne by you at the prevailing rates. ",
                    secondSubList:[]
                }
            ]
        },
        {
            title:"Confidentiality & Intellectual Property",
            introductions:[],
            firstSubList:[
                {
                    paragraph:"“Confidential Information” means non-public data, information and other materials regarding the products, software, services, or business of a party (and/or, if either party is bound to protect the confidentiality of any third party’s information, of a third party) provided by us to you where such information is marked or otherwise communicated as “proprietary” or “confidential,” or by its nature be reasonably considered confidential and/or proprietary. You agree that any information or data that we may provide to you in connection with our Services, is also Confidential Information.",
                    secondSubList:[]
                },
                {
                    paragraph:"Confidential Information shall not include information which: (i) is already known to you prior to disclosure by us; (ii) becomes publicly available without fault of you; (iii) is rightfully obtained by you from a third party without restriction on disclosure, or is approved for release by written authorisation from us; (iv) is independently developed or created by you without the use of Confidential Information; or (v) is required to be disclosed by Law or governmental regulation, provided that, when legally permissible, you provide reasonable notice to us of such required disclosure and reasonably cooperates with us in limiting such disclosure.",
                    secondSubList:[]
                },
                {
                    paragraph:"Subject to the contrary in the Agreement, you agree to: (i) use the Confidential Information only to perform or exercise rights granted under the Agreement; (ii) treat all Confidential Information in the same manner as it treats its own proprietary information, but in no case with less than reasonable care; and (iii) disclose the Confidential Information only to your employees and contractors who have a need to know such information, provided that any recipient shall be subject to obligations of confidentiality substantially similar to the terms of the Agreement. You shall be liable for the non-compliance of an employee or contractor.",
                    secondSubList:[]
                },
                {
                    paragraph:"You shall grant us a worldwide, non-exclusive, non-transferable, non-assignable, perpetual, irrevocable and royalty-free licence to (i) use and display the name, logos, trademarks or any other intellectual property rights of the entity you represent on our website and related marketing assets and identify you as our customer or business partner. To the extent that such name, logos, trademarks, or any other such intellectual property rights are owned by a person other than you or the entity you represent, you represent and warrant that you have entered into such arrangements with such persons as may be required for the grant of such rights and licences to us; (ii) use and publish your testimonials, feedback and case studies in publications, presentations and marketing assets created by us; and (iii) include you as a reference in our quotations, bidding documents, tender documents, submission for accreditations or awards or other documents of a similar nature. In the event of suspected copyright infringement, please email us at info@sequence.sg.",
                    secondSubList:[]
                }
            ]
        },
        {
            title:"Personal Data",
            introductions:"",
            firstSubList:[
                {
                    paragraph:"Notwithstanding our Data Protection Policy, you represent, undertake and warrant that:",
                    secondSubList:[
                        {
                            paragraph:"for any personal data of individuals that you will be or may be disclosing or disclose to us, that you would have prior to disclosing such personal data to us obtained consent from the individuals whose personal data are being disclosed, to:",
                            thirdSubList:[
                                {
                                    paragraph:"permit you to disclose the individuals’ personal data to us for the purposes set out in our Data Protection Policy; and"
                                },
                                {
                                    paragraph:"permit us (whether in Singapore or overseas) to collect, use, disclose and/or process the individuals’ personal data for the purposes set out in our Data Protection Policy;"
                                }
                            ]
                        },
                        {
                            paragraph:"you shall notify the individuals whose personal data are disclosed to us of the relevant purposes for such disclosure;",
                            thirdSubList:[]
                        },
                        {
                            paragraph:"any personal data of individuals that you disclose or will be disclosing to us is accurate. Further, you shall give us notice in writing as soon as reasonably practicable should you be aware that any such personal data has been updated and/or changed after such disclosure;",
                            thirdSubList:[]
                        },
                        {
                            paragraph:"you shall at our request, assist us to comply with all applicable Data Protection Laws; and",
                            thirdSubList:[]
                        },
                        {
                            paragraph:"for any personal data of individuals that you will be or may be disclosing to us, that you are validly acting on behalf of such individuals and that you have the authority of such individuals to provide their personal data to us and for us to collect, use, disclose and process such personal data for the purposes set out in our Data Protection Policy.",
                            thirdSubList:[]
                        }
                    ]
                },
                {
                    paragraph:"Without prejudice to the foregoing sub-provisions, you shall ensure that you comply with the Data Protection Laws, and that you will not do anything and not omit to do anything that will cause us to be in breach of any provision or requirement of the Data Protection Laws, whether now or in the future. You shall at our request, promptly do such things or execute such documents, as determined by us, in order to facilitate our compliance with the Data Protection Laws.",
                    secondSubList:[]
                },
                {
                    paragraph:"Notwithstanding anything to the contrary, you undertake to indemnify and at all times hereafter to keep us (together with our respective officers, employees and agents) (each an “Injured Party”) indemnified against any and all direct and indirect losses, Damages, actions, proceedings, costs, claims, demands, liabilities (including full legal costs on a solicitor and own client basis) which may be suffered or incurred by the Injured Party or asserted against the Injured Party by any person or entity whatsoever, in respect of any matter or event whatsoever arising out of, in the course of, by reason of or in respect of:",
                    secondSubList:[
                        {
                            paragraph:"any breach of any of the provisions in this Clause 4 by you; and/or",
                            thirdSubList:[]
                        },
                        {
                            paragraph:"any action or omission by you, that causes us to be in breach of the Data Protection Laws or any other applicable Laws.",
                            thirdSubList:[]
                        }
                    ]
                },
                {
                    paragraph:"For the avoidance of doubt, an Injured Party shall have the right to enforce this Clause 4.3 pursuant to and in accordance with the Contracts (Rights of Third Parties) Act 2001 of Singapore.",
                    secondSubList:[]
                }
            ]
        },
        {
            title:"Indemnity",
            introductions:"",
            firstSubList:[
                {
                    paragraph:"You undertake, jointly and severally, to indemnify and keep Sequence or any Sequence Personnel indemnified on demand against any losses, Damages, claims, proceedings, charges, costs and expenses which they may suffer or incur, directly or indirectly, as a result of the provision of any part of our Services (including any omission on your part to provide Sequence or Sequence Personnel, if any, with all relevant information to enable Sequence or Sequence Personnel to discharge their duties under applicable laws). For the avoidance of doubt, Sequence Personnel shall have the right to enforce this Clause 5 pursuant to and in accordance with Contracts (Rights of Third Parties) Act 2001 of Singapore.",
                    secondSubList:[]
                },
                {
                    paragraph:"The scope of the subjects-matter of such indemnity includes any data protection, confidentiality, intellectual property, or other matters such as your use of our Services, (ii) any breach of the Agreement by you, and (iii) any third party claim arising out of or relating to your Data and our use thereof, including without limitation if it infringes any rights (including intellectual property rights) of a third party. The scope of the indemnity shall specifically cover situations where the state of affairs or transactions or any act or omission of the entity(ies) creates any liability on Sequence from any third party, including government agencies and the like, provided that these were not wholly due to Sequence’s acts or omissions. An example is where incomplete or incorrect or inaccurate information, and failure to file or complete certain acts by a deadline in the past before Sequence took over to provide such Services.",
                    secondSubList:[]
                },
                {
                    paragraph:"Such indemnity shall not be limited or reduced whether or not the relevant facts, matters, or circumstances giving rise to the breach by you were known to Sequence or any Sequence Personnel. The rules of causation, remoteness of damage and mitigation of loss shall not apply to such indemnity claims.",
                    secondSubList:[]
                }
            ]
        },
        {
            title:"Limitation of Liability",
            introductions:"",
            firstSubList:[
                {
                    paragraph:"In no event shall we be liable for any special, consequential, incidental, or indirect damages, however caused and under any theory of liability arising out of the Agreement or otherwise, including without limitation loss of profits, loss of data, loss of goodwill, loss of reputation, loss of revenue, or loss of use, whether or not we have been advised of the possibility of such damages or circumstances, arising out of, resulting from, or relating in any way to (i) your use or inability to use our Services; (ii) the cost of procurement of substitute services; (iii) unauthorised use, access, or alteration of your Data, our Services; (iv) statements or conduct of any third party (even if we were the ones who were liaising on behalf of you or the third party); or (v) any other matter related to our Services.",
                    secondSubList:[]
                },
                {
                    paragraph:"Our aggregate liability for all losses or Damages (including interest thereon, if any) and costs suffered or incurred, directly or indirectly, by you or by any third party that may have the benefit of or rely upon our work, under or in connection with this engagement, whether as a result of breach of contract or statutory duty, tort (including negligence), or any other act or omission by us, but excluding any losses, Damages, or costs that cannot lawfully be limited or excluded, shall be limited to one (1) time of the fees we have received for all Services (including additional services), or one (1) time of the fees for the trailing twelve (12) month period before the date of the earliest claim that is raised with us, whichever is the lower.",
                    secondSubList:[]
                },
                {
                    paragraph:"You hereby acknowledge and agree that the provisions of the Agreement and the allocation of risk therein are essential elements of the bargain in our provision of our Services, without which we would not have provided our Services to you or entered into the Agreement.",
                    secondSubList:[]
                }
            ]
        },
        {
            title:"Term and Termination",
            introductions:"",
            firstSubList:[
                {
                    paragraph:"Unless terminated earlier in accordance with the Agreement, the Agreement shall commence on the date when you accepted the Agreement (including via the continued use of our Services as deemed acceptance) and remain in full force and effect while you use Services.",
                    secondSubList:[]
                },
                {
                    paragraph:"We shall be entitled to terminate the Agreement, and any agreement in relation to the provision of our Services, without penalty or liability or the obligation to refund any monies already paid to us, immediately by written notice in any of the following circumstances:",
                    secondSubList:[
                        {
                            paragraph:"we are required to do so to comply with any applicable Law; ",
                            thirdSubList:[]
                        },
                        {
                            paragraph:"you have breached any of their obligations under the Agreement and such breach is not remedied within five (5) calendar days of receipt of written notice requiring you to do so;",
                            thirdSubList:[]
                        },
                        {
                            paragraph:"you become the subject of a petition in bankruptcy or any other proceeding (including the passing of any resolution) relating to insolvency, receivership, liquidation, assignment for the benefit of creditors; ",
                            thirdSubList:[]
                        },
                        {
                            paragraph:"you are in breach of any part of the Agreement, or when Sequence anticipates any breach of any part of the Agreement;",
                            thirdSubList:[]
                        },
                        {
                            paragraph:"any continuous due diligence processes reveal any activities, publicity, or relationships that we are not acceptable of, including but not limited to any potential links to AML/CFT activities, reputational issues, disputes; or ",
                            thirdSubList:[]
                        },
                        {
                            paragraph:"if you engage in any illegal activity or is in any way non-compliant, or may become non-compliant, in relation to any regulated activities.",
                            thirdSubList:[]
                        }
                    ]
                },
                {
                    paragraph:"Upon any termination of the Agreement, or any agreement for the provision of our Services:",
                    secondSubList:[
                        {
                            paragraph:"our Services shall be immediately terminated and shall cease to be provided to you;",
                            thirdSubList:[]
                        },
                        {
                            paragraph:"your Data, may be deleted from our database at our discretion. You acknowledge and agree that we will not have any liability whatsoever to you for deletion your Data; ",
                            thirdSubList:[]
                        },
                        {
                            paragraph:"where applicable, you shall promptly pay all amounts due to us as of the effective date of termination;",
                            thirdSubList:[]
                        },
                        {
                            paragraph:"we shall not be liable to refund any amounts paid to us; and",
                            thirdSubList:[]
                        },
                        {
                            paragraph:"where applicable, you shall execute, or procure to be executed, all such documents and do all things to effect the resignation or replacement of Sequence Personnel which have been appointed as officers (e.g. director or secretary) of you. If the resignation or replacement of Sequence Personnel which have been appointed as officers of you are not effective, or cannot be effected, pursuant to any applicable law (e.g. where the Sequence Personnel is the sole director or resident director of you), you shall procure a suitable replace for such Sequence Personnel within 7 days of us notifying you to do so, failing which we shall be entitled, without prejudice to all other rights under the Agreement or otherwise, to apply to any court for specific performance, an order that you be wound up, damages, and you shall be liable to reimburse us and/or the Sequence Personnel for all fees, charges, costs and expenses incurred thereby (including legal costs on a full indemnity basis).",
                            thirdSubList:[]
                        }
                    ]
                },
                {
                    paragraph:"Any provision of the Agreement that expressly or by implication is intended to come into or continue in force on or after the termination of the Agreement shall remain in full force and effect.",
                    secondSubList:[]
                },
                {
                    paragraph:"Any provision of the Agreement that expressly or by implication is intended to come into or continue in force on or after the termination of the Agreement shall remain in full force and effect, including without limitation to, Clause 1 (Services), Clause 2 (Fees and Payment Terms), Clause 3 (Confidentiality), Clause 4 (Personal Data), Clause 5 (Indemnity), Clause 6 (Limitation of Liability), Clause 7 (Term and Termination), Clause 8 (Governing Law and Dispute Resolution), Clause 9 (General Provisions) and Clause 10 (Definitions).",
                    secondSubList:[]
                }
            ]
        },
        {
            title:"Governing Law and Dispute Resolution",
            introductions:"",
            firstSubList:[
                {
                    paragraph:"The Agreement shall be governed by and construed under the Laws of the Republic of Singapore.",
                    secondSubList:[]
                },
                {
                    paragraph:"If any dispute arises under, out of, or in connection with the Agreement, including any question regarding the existence, validity or termination of the same, you shall in good faith try to settle it by emailing dispute@sequence.sg.",
                    secondSubList:[]
                },
                {
                    paragraph:"In the event that the parties do not reach a settlement on a dispute after 60 days from the date of the email referred to in Clause 8.2, the parties agree to submit all such disputes arising out of or in connection with the Agreement to the exclusive jurisdiction of the courts of Singapore.",
                    secondSubList:[]
                }
            ]
        },
        {
            title:"General Provisions",
            introductions:"",
            firstSubList:[
                {
                    paragraph:"Entire Agreement. The Agreement supersedes all other prior and contemporaneous written and oral agreements and understandings between us and contain the complete agreement between the parties. The Agreement may be modified only by subsequent written agreement of the parties. You acknowledge that no promise has been made to you other than those stated in the Agreement.",
                    secondSubList:[]
                },
                {
                    paragraph:"Force Majeure. Sequence shall not be liable for any delay or failure to perform resulting from Force Majeure Events, including the inability to provide you with access to or use of our Services. If a Force Majeure Event occurs that affects Sequence’s performance of its obligations under the Agreement, Sequence’s obligations under the Agreement will be suspended and the time for Sequence’s performance of its obligations will be extended for the duration of the Force Majeure Event.",
                    secondSubList:[]
                },
                {
                    paragraph:"No Third-Party Beneficiaries. Save as expressly set out in the Agreement, no third party who is not a party to the Agreement (whether or not such person shall be named, referred to, or otherwise identified, or shall form part of a class of persons so named, referred to, or identified, in the Agreement) shall have any rights to enforce or rely upon any of the provisions of the Agreement whether under any Law (including the Contracts (Rights of Third Parties) Act 2001 of Singapore) or otherwise.",
                    secondSubList:[]
                },
                {
                    paragraph:"Notice. Where Sequence requires that you provide an email address, you are responsible for providing Sequence with your most current email address.  In the event that the last e-mail address you provided to Sequence is not valid, or for any reason is not capable of delivering to you any notices required/ permitted by the Agreement, Sequence’s dispatch of the e-mail containing such notice will nonetheless constitute effective notice. ",
                    secondSubList:[]
                },
                {
                    paragraph:"No Waiver. No failure or delay by either party in exercising any right under the Agreement shall constitute a waiver of that right.",
                    secondSubList:[]
                },
                {
                    paragraph:"Assignment. The Agreement, and your rights and obligations hereunder, may not be assigned, subcontracted, delegated or otherwise transferred by you without Sequence’s prior written consent, and any attempted assignment, subcontract, delegation, or transfer in violation of the foregoing will be null and void. Sequence shall have the right to assign the Agreement without your consent and without prior notice to you. Subject to the foregoing, the Agreement shall be binding upon and shall inure to the benefit of the parties and their respective successors and assigns.",
                    secondSubList:[]
                },
                {
                    paragraph:"Severability. If a court or tribunal of competent jurisdiction finds any provision of the Agreement invalid or unenforceable, that provision of the Agreement will be amended to achieve as nearly as possible the intent of the parties, and the remainder of the Agreement will remain in full force and effect.",
                    secondSubList:[]
                },
                {
                    paragraph:"Language. The Agreement is made in the English language only, and any translation of these terms of service in another language shall not be binding upon the parties.",
                    secondSubList:[]
                },
                {
                    paragraph:"Novation or Assignment as part of Sequence Corporate Initiatives. You agree that there may be future corporate re-organisations that may require the Agreement to be novated between you, Sequence, and any target entity(ies) that Sequence may require, and that you will provide the necessary approvals and acts to effect such re-organisations by making the target entity(ies) the counter-party to the Agreement. In any case, there shall not be any disruption to the Services nor any additional fees payable in respect of such re-organisations on your end.",
                    secondSubList:[]
                }
            ]
        },
        {
            title:"Definitions",
            introductions:"",
            firstSubList:[
                {
                    paragraph:"In the Agreement, the following words and expressions shall have the meanings respectively assigned to them hereunder:",
                    secondSubList:[
                        {
                            paragraph:"\"Damages\" means all direct and indirect liabilities, losses (including loss of profits, sales, business, revenue, business opportunity, goodwill, anticipated savings, reputation, or any other consequential losses), damages (including incidental, exemplary, special, punitive, and multiple damages or any other indirect damages), costs and expenses, fines and penalties, fees on a full indemnity basis and disbursements and costs of investigation, litigation, settlement, judgement and interest regardless of whether based upon warranty, contract, tort, statute, strict liability or otherwise.",
                            thirdSubList:[]
                        },
                        {
                            paragraph:"“Data” means the data and information created, submitted to, or provided to Sequence by you for the purposes of providing you the Services, and for the avoidance of doubt, shall include your digital signature. ",
                            thirdSubList:[]
                        },
                        {
                            paragraph:"“Data Protection Laws” means the Personal Data Protection Act 2012 of Singapore and all subsidiary legislation for the time being in force.",
                            thirdSubList:[]
                        },
                        {
                            paragraph:"\"Force Majeure Event\" means any circumstance not within a party's reasonable control, including, without limitation (a) fire, storm, lightning, flood, drought, earthquake or other natural disaster or other similar acts of God; (b) epidemic or pandemic (including the COVID-19 pandemic or similar events); (c) terrorist attack, civil war, civil commotion or riots, war, threat of or preparation for war, armed conflict, imposition of sanctions, embargo, or breaking off of diplomatic relations; (d) any change in Laws or any action taken by a Governmental Authority, including without limitation imposing an export or import restriction, quota or prohibition, or failing to grant a necessary licence or consent; (e) fire, explosion or accident; (f) any labour or trade dispute, strikes, industrial action or lockouts; (g) non-performance by suppliers or subcontractors; and (h) the unavailability, interruption or failure of utility services such as electricity, gas, water, and telecommunications or major internet or cloud service providers or data centre.",
                            thirdSubList:[]
                        },
                        {
                            paragraph:"\"Governmental Authority\" means any governmental, administrative, statutory, regulatory or self-regulatory, judicial or arbitral authority or body (including any division thereof), anywhere in the world with jurisdiction over the relevant affairs of Sequence or you.",
                            thirdSubList:[]
                        },
                        {
                            paragraph:"\"Laws\" means any applicable statute, regulation, by-law, ordinance or subordinate or subsidiary legislation in force from time to time, including the common law as may be applicable from time to time and any applicable industry codes or standards which are of a mandatory and binding nature.",
                            thirdSubList:[]
                        },
                        {
                            paragraph:"\"Personal Data\" means data, whether true or not, about an individual who can be identified (a) from that data; or (b) from that data and other information to which a party has or is likely to have access.",
                            thirdSubList:[]
                        },
                        {
                            paragraph:"“Sequence Personnel” means Sequence, or any of its officers, employees, staff, representatives, and/or agents, and includes any such persons that have been appointed to act as a nominee director or nominee shareholder or company secretary. ",
                            thirdSubList:[]
                        },
                        {
                            paragraph:"“Services'' means the services (including any information and content available therein) provided by Sequence. These services include anything that may be agreed between Sequence and you (whether by way of email, WhatsApp or other form of communication, whether or not encapsulated in a quote or letter of engagement). Our Services shall include any further services as agreed between Sequence and you at any point in time (including any services or goods provided before the effective date of the Agreement but that were contemplated to be governed by the Agreement).",
                            thirdSubList:[]
                        },
                    ]
                },
                {
                    paragraph:"Any words following the terms “including”, “include”, “in particular”, or “for example” or any similar phrase shall be construed as illustrative and shall not limit the generality of the related general words. ",
                    secondSubList:[]
                }
            ]
        }
    ]
}