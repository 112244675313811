import React from "react";
import { Box, Heading, List, ListItem, Text } from "@chakra-ui/react";

const DocumentArea = (props) => {
    const {
        data
    } = props;
  return (
    <Box p={5} border="1px solid #ccc" borderRadius="md" w="fit-content" color="white">
        <Heading size={"2xl"}>
            {data.title}
        </Heading>
        <Text>{data.lastUpdatedDate}</Text>
        {data.introductions?.map((item)=>{
            return(
                <Text textAlign={"justify"} my="10px">{item}</Text>
            )
        })}
        <List spacing={2} textAlign={"left"}>
        {
            data.terms?.map((item,index) => {
                return(<>
                <Heading size={"md"}>
                    <ListItem>
                        {(index + 1) + ". " + item.title}
                    </ListItem>
                </Heading>
                <List spacing={3}>
                    {
                        item.firstSubList?.map((fItem, fIndex) => {
                            return(
                                <>
                                <ListItem>{(index + 1) + "." + (fIndex + 1) + " " + fItem.paragraph}</ListItem>
                                <List spacing={2} styleType="lower-alpha" pl={4}>
                                    {
                                        fItem.secondSubList?.map(((sItem) => {
                                            return(<>
                                            <ListItem>{sItem.paragraph}</ListItem>
                                            <List spacing={2} styleType="lower-roman" pl={4}>
                                                {
                                                    sItem.thirdSubList?.map((tItem) => {
                                                        return(<>
                                                        <ListItem>{tItem.paragraph}</ListItem>
                                                        </>)
                                                    })
                                                }
                                            </List>
                                            </>)
                                        }))
                                    }
                                </List>
                                </>
                            )
                        })
                    }
                </List>
                </>)
            })
        }
        </List>
    </Box>
  );
};

export default DocumentArea;
